import React from 'react';
import Navbar from './../common/Navbar';
import Footer from './../common/Footer';
import { Grid, Button } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import "../../assets/styles/home.scss";

const Home = () => {

  const onClickIos = () => {
    window.location.href = 'http://google.com';
  }

  const onClickAndroid = () => {
    window.location.href = 'http://google.com';
  }

  return (
    <React.Fragment>
      <Navbar />
      <div className={`home ${isMobile && "mobile"}`}>
        <div className={`download-text ${isMobile && "mobile"}`}>
          Download our apps now!
        </div>
        <Grid className="download-section" justify="center" container spacing={isMobile ? 0 : 2}>
          <Grid item xs={isMobile ? 12 : false}>
            <Grid container justify="center" spacing={isMobile ? 0 : 5}>
              <Grid item>
                <div className="qr-code">
                  <img alt="popsical-qrlink" className="qr-image" src={require(`../../assets/images/qr-popsicalonelink.png`).default} />
                </div>
              </Grid>
              <Grid className={`or ${isMobile && "mobile"}`} item xs={isMobile ? 12 : false}>
                <span>or</span>
              </Grid>
              <Grid className={`download-app-link ${isMobile && "mobile"}`} item xs={isMobile ? 12 : false}>
                <div>
                  <Button className="download-button" onClick={onClickIos}>
                    <img alt="popsical-ioslink" className="qr-image" src={require(`../../assets/images/bttn-appstore.svg`).default} />
                  </Button>
                </div>
                <div>
                  <Button className="download-button" onClick={onClickAndroid}>
                    <img alt="popsical-androidlink" className="qr-image" src={require(`../../assets/images/bttn-google-play.svg`).default} />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Home;
