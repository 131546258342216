import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Player } from "video-react";
import { Grid, Avatar, Button } from "@material-ui/core";
import Navbar from "./../common/Navbar";
import Footer from "./../common/Footer";
import Loader from "./../common/Loader";
import { isMobile } from "react-device-detect";
import "../../assets/styles/shot.scss";
import Popsical from "./../../lib/api/Popsical";
import HLSSource from "../common/HLSSource";

const Shot = () => {
  const [shot, setShot] = useState(null);
  const { shotId } = useParams();

  useEffect(() => {
    if (shot == null) {
      getShotData(shotId);
    }
  }, [shot, shotId]);

  const getShotData = async (shotId) => {
    await Popsical.get(
      "v4/public/shot",
      { shot_id: shotId },
      { usePublicHeaders: true }
    )
      .then((response) => {
        setShot(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getName = (shot) => {
    if (shot.user.firstname != null) {
      return shot.user.firstname;
    } else {
      return shot.user.username;
    }
  };

  const getThumbnail = (shot) => {
    if (shot.thumbnail_url) {
      return shot.thumbnail_url;
    } else {
      return require(`../../assets/images/default-video-image.jpg`).default;
    }
  };

  const onClickIos = () => {
    window.location.href = "http://google.com";
  };

  const onClickAndroid = () => {
    window.location.href = "http://google.com";
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className={`shot ${isMobile && "mobile"}`}>
        {shot ? (
          <Grid container spacing={isMobile ? 0 : 2}>
            <Grid item className="remove-padding-top" xs={12}>
              <Grid container justify="center" spacing={isMobile ? 0 : 10}>
                <Grid item xs={isMobile ? 12 : false}>
                  <div className={`video-player ${isMobile && "mobile"}`}>
                    {shot.stream_url.endsWith(".mp4") ? (
                      <Player
                        playsInline
                        poster={getThumbnail(shot)}
                        src={shot.stream_url}
                      />
                    ) : (
                      <Player playsInline poster={getThumbnail(shot)}>
                        <HLSSource isVideoChild src={shot.stream_url} />
                      </Player>
                    )}
                  </div>
                </Grid>
                <Grid item xs={isMobile ? 12 : false}>
                  <div className="content">
                    <div className={`profile ${isMobile && "mobile"}`}>
                      <div className="song-name">
                        <span>{shot.track.title}</span>
                      </div>
                      <div className="artist-name">
                        <span>{shot.track.artist.length ? shot.track.artist[0].name : ""}</span>
                      </div>
                      <div className="avatar-profile">
                        <Avatar className="avatar" src={shot.user.profile_pic}>
                          {getName(shot)}
                        </Avatar>
                        <div className="name">
                          <span>{getName(shot)}</span>
                        </div>
                      </div>
                      <div className="description">
                        <span>{shot.description}</span>
                      </div>
                    </div>
                    <div className={`scan-description ${isMobile && "mobile"}`}>
                      <span>
                        Scan the QR code below to download the Popsical Karaoke
                        app and start creating your own shots!
                      </span>
                    </div>
                    <Grid
                      className="download-section"
                      justify="center"
                      container
                      spacing={isMobile ? 0 : 2}
                    >
                      <Grid item xs={isMobile ? 12 : false}>
                        <Grid
                          container
                          justify="center"
                          spacing={isMobile ? 0 : 5}
                        >
                          <Grid item>
                            <div className="qr-code">
                              <img
                                alt="popsical-qrlink"
                                className="qr-image"
                                src={
                                  require(`../../assets/images/qr-popsicalonelink.png`)
                                    .default
                                }
                              />
                            </div>
                          </Grid>
                          <Grid
                            className={`or ${isMobile && "mobile"}`}
                            item
                            xs={isMobile ? 12 : false}
                          >
                            <span>or</span>
                          </Grid>
                          <Grid
                            className={`download-app-link ${
                              isMobile && "mobile"
                            }`}
                            item
                            xs={isMobile ? 12 : false}
                          >
                            <div>
                              <Button
                                className="download-button"
                                onClick={onClickIos}
                              >
                                <img
                                  alt="popsical-ioslink"
                                  className="qr-image"
                                  src={
                                    require(`../../assets/images/bttn-appstore.svg`)
                                      .default
                                  }
                                />
                              </Button>
                            </div>
                            <div>
                              <Button
                                className="download-button"
                                onClick={onClickAndroid}
                              >
                                <img
                                  alt="popsical-androidlink"
                                  className="qr-image"
                                  src={
                                    require(`../../assets/images/bttn-google-play.svg`)
                                      .default
                                  }
                                />
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div className="loading">
            <Loader className="loader" />
          </div>
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Shot;
