import React from "react";
import { Grid } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import "../../assets/styles/footer.scss";

const Footer = () => {
  return (
    <div className={`footer ${isMobile && "mobile"}`}>
      <Grid container spacing={isMobile ? 0 : 2}>
        <Grid item xs={12}>
          <Grid container spacing={isMobile ? 0 : 3}>
            <Grid item xs={isMobile ? 6 : 3}>
              <div className={`content ${isMobile && "mobile"}`}>
                <div className="title">
                  <span>COMPANY</span>
                </div>
                <div className="item">
                  <a href="/#">About Us</a>
                  <a href="/#">Contact</a>
                  <a href="/#">Press Kit</a>
                  <a href="/#">Career</a>
                  <div className={`policy ${isMobile && "mobile"}`}>
                    <a href="/#" className="special">
                      Private Policy
                    </a>
                    <span>All rights reserved &#169; 2019</span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={isMobile ? 6 : 3}>
              <div className={`content ${isMobile && "mobile"}`}>
                <div className="title">
                  <span>USEFUL LINKS</span>
                </div>
                <div className="item">
                  <a href="/#">Our Store</a>
                  <a href="/#">Mobile App</a>
                  <a href="/#">Help</a>
                </div>
              </div>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <div className={`soc-meds ${isMobile && "mobile"}`}>
                <Grid container spacing={3}>
                  <Grid item>
                    <img
                      alt="facebook"
                      src={
                        require(`../../assets/images/icons-social-media-facebook.svg`)
                          .default
                      }
                    />
                  </Grid>
                  <Grid item>
                    <img
                      alt="instagram"
                      src={
                        require(`../../assets/images/icons-social-media-instagram.svg`)
                          .default
                      }
                    />
                  </Grid>
                  <Grid item>
                    <img
                      alt="twitter"
                      src={
                        require(`../../assets/images/icons-social-media-twitter.svg`)
                          .default
                      }
                    />
                  </Grid>
                  <Grid item>
                    <img
                      alt="youtube"
                      src={
                        require(`../../assets/images/icons-social-media-youtube.svg`)
                          .default
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={`mobile-policy ${isMobile && "mobile"}`}
            >
              <a href="/#" className="special">
                Private Policy
              </a>
              <span>All rights reserved &#169; 2019</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
